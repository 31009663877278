import {
    GETTING_IMMUNE_OVERDUE,
    GETTING_IMMUNE_OVERDUE_SUCCESS,
    GETTING_IMMUNE_OVERDUE_MORE_SUCCESS,
    GETTING_IMMUNE_OVERDUE_FAILURE
} from "../constants/Constant";
import getAllImmune from "../services/ImmuneService";
import getUserId from "../core/GetToken";

import Settings from '../config/Settings'

export const fetchOverdueImmune = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getImmuneOverdue())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: pageSize,
                PageNumber: pageNumber,
                TypeID: 2,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            // const queryString = '?Token=b25bbd77-4d40-4bb4-be11-d7a136033cd9&PatientID=969691&PageSize=10&PageNumber=1&TypeID=2';

            fetch(Settings.apiUrl + "Patient/Immunization", requestOptions)
                .then(data => data.json())
                .then(json => {
                    if (json) {

                        json[0].hasOwnProperty('entry') ? dispatch(getImmuneOverdueSuccess(json[0].entry)) : dispatch(getImmuneOverdueFinshed())
                    } else {
                        dispatch(getImmuneOverdueFinshed())
                    }

                })
                .catch(err => dispatch(getImmuneOverdueFail(err)))
        })

        // getAllImmune(queryString)
        //         .then(data => data.json())
        //         .then(json => {
        
        //             json[0].hasOwnProperty('entry') ? dispatch(getImmuneOverdueSuccess(json[0].entry)) : dispatch(getImmuneOverdueFinshed())
        //         })
        //         .catch(err => dispatch(getImmuneOverdueFail(err)))
        // })
    }
}

export const fetchMoreOverdueImmune = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getImmuneOverdue())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&TypeID=2';
            getAllImmune(queryString)
                .then(data => data.json())
                .then(json => {
                   
                    json[0].hasOwnProperty('entry') ? dispatch(getMoreImmuneOverdueSuccess(json[0].entry)) : dispatch(getImmuneOverdueFinshed())
                })
                .catch(err => dispatch(getImmuneOverdueFail(err)))
        })
    }
}



const getImmuneOverdue = () => {
    return {
        type: GETTING_IMMUNE_OVERDUE
    }
}

//data success
const getImmuneOverdueSuccess = (data) => {
    return {
        type: GETTING_IMMUNE_OVERDUE_SUCCESS,
        data,
    }
}


const getMoreImmuneOverdueSuccess = (data) => {
    return {
        type: GETTING_IMMUNE_OVERDUE_MORE_SUCCESS,
        data
    }
}
//data failed
const getImmuneOverdueFail = () => {
    return {
        type: GETTING_IMMUNE_OVERDUE_FAILURE
    }
}

const getImmuneOverdueFinshed = () => {
    return "null";
}