import { Button, Modal } from "antd";
import { async } from "q";
import React, { useEffect, useState } from "react";
import { View, Text, Alert } from "react-native";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Settings from "../../shared/config/Settings";
import getUserId from "../../shared/core/GetToken";
import { actionAPI } from "../../shared/reduxToolkit/actionAPI";
// react-scripts --openssl-legacy-provider start
const PaymentSuccessRoute = () => {
  const [paymentData, setPaymentData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [repeatRequestSuccessful, setRepeatRequestSuccessful] = useState(false);
  const [isCallFromWeb, setisCallFromWeb] = useState(false);
  const [isCallFromRpeatRX, setIsCallFromRpeatRX] = useState(false);
  const [paymentMethod, setpaymentMethod] = useState(null);
  const [paymentChecking, setPaymentChecking] = useState("loading");
  const [sendingMedicationRepeatRequest, setSendingMedicationRepeatRequest] = useState(false);

  const navigate = useNavigate()
  let repeatPayload
  const search = useLocation().search
  const apiDispatcher = useDispatch()

  const repeatMedicationRequest = (payload) => {
    getUserId().then(userInfo => {
      const item = JSON.parse(userInfo);
      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");

      setSendingMedicationRepeatRequest(true)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: 'follow'
      };
      console.log("requestOptionsssss => ", requestOptions)

      let statusMed
      fetch(Settings.apiUrl + "Patient/SendRepeatMedicationRequest", requestOptions)
        .then(response => {
          statusMed = response.status
          return response.json()
        })
        .then((result) => {
          setSendingMedicationRepeatRequest(false)
          if (result.indexOf("successfully") >= 0) {
            setRepeatRequestSuccessful(true)
            apiDispatcher(actionAPI.GetAppSettingAPI())
            window.history.pushState('', '', '/paymentsuccess?checked=True');
          }
          // setIsModalOpen(true)
        })
    })
  }  
  const repeatMedicationRequest_Mobile = async (payload,patienttoken,apiUrl) => {
    var myHeaders2 = new Headers();
    myHeaders2.append("Token", patienttoken);
    myHeaders2.append("Content-Type", "application/json");
      const requestOptions = {
        method: "POST",
        headers: myHeaders2,
        body: JSON.stringify(payload),
        redirect: "follow",
      };
      setSendingMedicationRepeatRequest(true)
      let statusMed;
      fetch(
        apiUrl + '/api/Patient/SendRepeatMedicationRequest',
        requestOptions
      )
        .then((response) => {
          statusMed = response.status;
          // alert(response.status);
          return response.json();
        })
        .then((result) => {
          // alert("repeat-----"+JSON.stringify(result))
          setSendingMedicationRepeatRequest(false);

          if (result.indexOf("successfully") >= 0) {
            setRepeatRequestSuccessful(true)
            setPaymentChecking("success")
          }
       
        });
    
  }
  useEffect(async () => {
    function parseQuery(queryString) {
      const params = {};
      const queryStringWithoutQuestionMark = queryString.substring(1);
      const queryParams = queryStringWithoutQuestionMark.split("&");
      queryParams.forEach((param) => {
        const [key, value] = param.split("=");
        params[key] = decodeURIComponent(value);
      });
      return params;
    }


    const queryString = window.location.search;
    let { apiUrl, pid, patienttoken, paymenttype, callfrom, IsRxRequest, temppaymentid, token, patientid, abctick, sessionId, checked } = parseQuery(queryString);

    setIsCallFromRpeatRX(IsRxRequest === "True" ? true : false)

    if (checked === "True") {
      setPaymentChecking("success")
      setRepeatRequestSuccessful(true)
    }
    if (callfrom == "mobile") {
      setisCallFromWeb(false)
    } else {
      setisCallFromWeb(true)
    }
    setpaymentMethod(paymenttype)
    // alert(window.location.search);
    debugger
    apiUrl = abctick;
    // alert(apiUrl)
    setTimeout(async () => {

    if(callfrom == "mobile" && paymenttype =="stripe")
    {
     
      var myHeaders = new Headers();
      myHeaders.append("Token", token);
      myHeaders.append("Content-Type", "application/json");
      let IsAutoAdjustment

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify({
          TempPaymentID: temppaymentid
        }) 
      };

      fetch(apiUrl + "/api/Patient/GetTempPaymentSessionID", requestOptions)
        .then((response) => response.json())
        .then((result) => {

         console.log("GetTempPaymentSessionID response", JSON.stringify(result))

         if (JSON.parse(result?.RepeatPayload)?.split("&repeat=")[1]) {
          IsAutoAdjustment = JSON.parse(result?.RepeatPayload)?.split("&IsAutoAdjustment=")[1]?.indexOf("True") === 0
           repeatPayload = JSON.parse(result?.RepeatPayload)?.split("&repeat=")[1]
           repeatPayload = JSON.parse(repeatPayload)
         }

          var raw2 = JSON.stringify({
            "SessionID": result?.PaymentSessionID,
            "PatientID": patientid,

          });
// alert('slss' + repeatPayload)       

          var requestOptions2 = {
            method: 'POST',
            headers: myHeaders,
            body: raw2,
            redirect: 'follow'
          };
          fetch(apiUrl + "/api/Patient/GetStripeTransactionBySession", requestOptions2)
            .then(response => {
              return response.json()
            })
            .then(json => {     
              console.log('axaxa',JSON.stringify(json))

              if (json?.response?.payment_status === "paid" || json?.response?.payment_status === "unpaid") {
               

                const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + IsAutoAdjustment : "IsAutoAdjustment=" + false}`;

                fetch(apiUrl + "/api/Patient/SavePayment2" + payloadqueryStringFinal, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    "Token": token,
                  },
                  redirect: 'follow',
                })
                  .then((response) => response.json())
                  .then((result) => {
                    setPaymentChecking("success")
                  //  alert(JSON.stringify(result))


                    if (result && repeatPayload) {
                      // openNotificationWithIcon('success', 'Payment succeeded successfully')
                      // alert("inside resullt and repelapayloadddd")
                      setPaymentChecking("success")
                      repeatMedicationRequest_Mobile(repeatPayload,token,apiUrl)
                    }
                    else if (result) {
                      setPaymentChecking("success")
                      setRepeatRequestSuccessful(true)
                      // setIsModalOpen(true)
                    } else {
                      // openNotificationWithIcon('error', 'Payment unsuccessful')

                    }

                  });
              }

            })
        // } else {
        //   setPaymentChecking("failed")
        // }
        })
        .catch(err => console.log(err))
      
    }
    else if (callfrom == "mobile" && paymenttype == "poli") {
      setPaymentChecking("loading")
      let IsAutoInvoice = false

      const myHeaders = new Headers();
      myHeaders.append("Token", patienttoken);
      myHeaders.append("Content-Type", "application/json");
  
      const raw = JSON.stringify({
        TransactionID: token,
        PatientID: patientid,
        // ApiUrl: `https://devdhbconnect.vitonta.com`,
        // ApiUrl: apiUrl,
      });
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      // alert("xcxc"+patienttoken)
      // alert(raw);
      // alert(apiUrl)
      const resultPoliTransaction = await fetch(apiUrl + "/api/Patient/GetPoliTransaction",
        requestOptions
      );
      // fetch("https://devdhbconnect.vitonta.com/api/Patient/GetPoliTransaction",
      //   requestOptions
      // )
      //   .then(response => {
      //     return response.json()
      //   })
      //   .then(json => {
      //     alert("asdasd"+JSON.stringify(json))
      //   })
      // alert("jijijij"+JSON.stringify(resultPoliTransaction))
      const finalresultPoliTransaction = await resultPoliTransaction.json();
      setPaymentData(finalresultPoliTransaction);
      // alert("niccc"+JSON.stringify(finalresultPoliTransaction));
 
      if(finalresultPoliTransaction?.MerchantData?.indexOf("repeat") == -1){
          //SavePaymentCall
          if (finalresultPoliTransaction.TransactionStatus == "Completed") {
            // if (repeatJsonTID && parseInt(repeatJsonTID) > 0) {
            if (temppaymentid ) {
              const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + true : "IsAutoAdjustment=" + false}`;
              // alert(payloadqueryStringFinal)
              const responsePaymentFinal = await fetch(
                apiUrl + '/api/patient/SavePayment2' + payloadqueryStringFinal,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Token: patienttoken,
                  },
                  redirect: "follow",
                }
              );
            }
          }
          setPaymentChecking("success")
          // alert("Accountssss")
          return;
      }
      const startIndex =
      finalresultPoliTransaction?.MerchantData?.indexOf("repeat=") + 7;
    const endIndex = finalresultPoliTransaction?.MerchantData?.lastIndexOf("}");
    const repeatJson = finalresultPoliTransaction?.MerchantData?.substring(
      startIndex,
      endIndex + 1
    );
    IsAutoInvoice = finalresultPoliTransaction?.MerchantData?.split("&IsAutoAdjustment=")[1]?.indexOf("True") >= 0

    repeatPayload = JSON.parse(repeatJson);
 
    // alert("repeattttttt"+ JSON.stringify(repeatPayload));
 
    // const startIndexTID =
    //   finalresultPoliTransaction?.MerchantData?.indexOf("temppaymentid=") + 14;
    // const endIndexTID =
    //   finalresultPoliTransaction?.MerchantData?.lastIndexOf("*");
    // const repeatJsonTID = finalresultPoliTransaction?.MerchantData?.substring(
    //   startIndexTID,
    //   endIndexTID
    // );
    // alert("Repeatttttt"+ JSON.stringify(repeatJsonTID))
    if (finalresultPoliTransaction.TransactionStatus == "Completed") {
      // alert("inside1")
      if (temppaymentid ) {
        // alert("inside2")
        const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + IsAutoInvoice : "IsAutoAdjustment=" + false}`;
        const responsePaymentFinal = await fetch(
          apiUrl + '/api/patient/SavePayment2' + payloadqueryStringFinal,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Token: patienttoken,
            },
            redirect: "follow",
          }
        );
 
        var myHeaders2 = new Headers();
        myHeaders2.append("Token", patienttoken);
        myHeaders2.append("Content-Type", "application/json");
        const finalpaymentResult = await responsePaymentFinal.json();
        if (finalpaymentResult) {
          setSendingMedicationRepeatRequest(true);
          const requestOptions = {
            method: "POST",
            headers: myHeaders2,
            body: JSON.stringify(repeatPayload),
            redirect: "follow",
          };
 
          let statusMed;
          fetch(
            apiUrl + '/api/Patient/SendRepeatMedicationRequest',
            requestOptions
          )
            .then((response) => {
              statusMed = response.status;
              // alert(response.status);
              return response.json();
            })
            .then((result) => {
              // alert("repeat-----"+result)
              setSendingMedicationRepeatRequest(false);
              setPaymentChecking("success");
              setRepeatRequestSuccessful(true);
            });
        }
      }
    }
    }
    else if(callfrom == "mobile" && paymenttype =="windcave"){
      
      setPaymentChecking("loading")
      var myHeaders = new Headers();
      myHeaders.append("Token", token);
      myHeaders.append("Content-Type", "application/json");
      let IsAutoAdjustment = false

      let raw = {
        "PatientID": patientid,
        "SessionID": sessionId
      }
      console.log(raw)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(raw)
      };
      console.log(apiUrl + "/api/Patient/GetWindCaveSessionById")

      fetch(apiUrl + "/api/Patient/GetWindCaveSessionById", requestOptions)
        .then(response => {
          return response.json()
        })
        .then(json => {
          if (json.state === "complete") {
 

                  var myHeaders = new Headers();
                  myHeaders.append("Token", token);
                  myHeaders.append("Content-Type", "application/json");
 
                  var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify({
                      TempPaymentID: temppaymentid
                    }) 
                  };
                  // alert(apiUrl)
                  // alert(temppaymentid)

            fetch(apiUrl + "/api/Patient/GetTempPaymentSessionID", requestOptions)
                    .then(response => {
                      return response.json()
                    })
                    .then(json => {
                      // alert(JSON.parse(JSON.parse(json?.RepeatPayload)?.key2))
                      // alert(token)
                      // alert(apiUrl)
                      // alert(JSON.stringify(json)   )
                      if (IsRxRequest === "True" && JSON.parse(json?.RepeatPayload)?.key1) {
                        IsAutoAdjustment = JSON.parse(json?.RepeatPayload)?.key1?.split("&IsAutoAdjustment=")[1]?.indexOf("True") >= 0
                      }
                      if (IsRxRequest === "True" && JSON.parse(JSON.parse(json?.RepeatPayload)?.key2)) {
                        // alert("inside repeatt")
                        repeatPayload = JSON.parse(JSON.parse(json?.RepeatPayload)?.key2)

                      }
                      const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + IsAutoAdjustment : "IsAutoAdjustment=" + false}`;
 
                      fetch(apiUrl + "/api/Patient/SavePayment2" + payloadqueryStringFinal, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          "Token": token,
                        },
                        redirect: 'follow',
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          setPaymentChecking("success")

                          if (IsRxRequest === "true" || IsRxRequest === "True") {

                          repeatMedicationRequest_Mobile(repeatPayload,token,apiUrl)
                          }
                        })
                      // alert("outside repeatt")
                    })
                // } else {
                //   // alert("else success")
                //   setPaymentChecking("success")
                // }
            
          }
        })
    }
    else if (callfrom == "web" && paymenttype == "poli") {
      async function ftPaymentCall() {
        setPaymentChecking("loading")
        const pid = new URLSearchParams(search).get('pid');
        const token = new URLSearchParams(search).get('token');
        let repeatPayload
        let IsAutoInvoice = false
        const item = await getUserId().then((userInfo) => {
          return JSON.parse(userInfo);
        });

        var myHeaders = new Headers();
        myHeaders.append("Token", item.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "TransactionID": token,
          "PatientID": item.userid,

        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        const resultPoliTransaction = await fetch(`${Settings.apiUrl}Patient/GetPoliTransaction`, requestOptions);
        const finalresultPoliTransaction = await resultPoliTransaction.json();
        setPaymentData(finalresultPoliTransaction);
        if (finalresultPoliTransaction?.MerchantData?.indexOf('repeat=') >= 0) {

        const startIndex = finalresultPoliTransaction?.MerchantData?.indexOf('repeat=') + 7;
        const endIndex = finalresultPoliTransaction?.MerchantData?.lastIndexOf('}');
        const repeatJson = finalresultPoliTransaction?.MerchantData?.substring(startIndex, endIndex + 1);
          IsAutoInvoice = finalresultPoliTransaction?.MerchantData?.split("&IsAutoAdjustment=")[1]?.indexOf("True") >= 0

        repeatPayload = JSON.parse(repeatJson);
        console.log("lorem Ipsum")
        console.log("repeattttttt", repeatPayload)
        }


        if (finalresultPoliTransaction.TransactionStatus == "Completed") {

          ///////////////////////////final payment call//////////////////////////////////////////////////////

          if (temppaymentid) {

            ///////////////neeed to get temppaymentResult id from lcalstorage
            const payloadqueryStringFinal = `?PatientID=${item.userid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + IsAutoInvoice : "IsAutoAdjustment=" + false}`
            const responsePaymentFinal = await fetch(`${Settings.apiUrl}patient/SavePayment2${payloadqueryStringFinal}`, {
              method: 'POST',
              headers: {
                "Content-Type": "application/json",
                "Token": item.token
              },
              redirect: 'follow'
            });

            const finalpaymentResult = await responsePaymentFinal.json();

            if (finalpaymentResult && finalresultPoliTransaction?.MerchantData?.indexOf('repeat=') >= 0 && repeatPayload) {
              // openNotificationWithIcon('success', 'Payment succeeded successfully')
              setPaymentChecking("success")
              repeatMedicationRequest(repeatPayload)
            }
            else if (finalpaymentResult) {
              setPaymentChecking("success")
              window.history.pushState('', '', '/paymentsuccess?checked=True');
              setRepeatRequestSuccessful(true)
              // setTimeout(() => {
              //   navigate("/accounts")
              // }, 2000)

              // setIsModalOpen(true)
            } else {
              // openNotificationWithIcon('error', 'Payment unsuccessful')

            }
          }
          else {
            // openNotificationWithIcon('error', 'Payment unsuccessful')
          }
          ///////////////////////////final payment call END//////////////////////////////////////////////////////
        }
      }
      try {
        ftPaymentCall();
      }
      catch (error) {
        console.log('error', error)
      }
    }
    else if (callfrom == "web" && paymenttype == "stripe") {
      const item = await getUserId().then((userInfo) => {
        return JSON.parse(userInfo);
      });
      setPaymentChecking("loading")
      let IsAutoAdjustment
      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify({
          TempPaymentID: temppaymentid
        }) 
      };
      fetch(Settings.apiUrl + "Patient/GetTempPaymentSessionID", requestOptions)
        .then(response => {
          return response.json()
        })
        .then(json => {
          console.log("GetTempPaymentSessionID response", json)
          if (JSON.parse(json?.RepeatPayload)?.split("&repeat=")[1]) {
            IsAutoAdjustment = JSON.parse(json?.RepeatPayload)?.split("&IsAutoAdjustment=")[1]?.indexOf("True") === 0

            repeatPayload = JSON.parse(json?.RepeatPayload)?.split("&repeat=")[1]
            repeatPayload = JSON.parse(repeatPayload)
          }

          if (typeof (json?.PaymentSessionID) === "string") {

            var raw2 = JSON.stringify({
              "SessionID": json?.PaymentSessionID,
              "PatientID": item.userid,

            });

            var requestOptions2 = {
              method: 'POST',
              headers: myHeaders,
              body: raw2,
              redirect: 'follow'
            };
            fetch(Settings.apiUrl + "Patient/GetStripeTransactionBySession", requestOptions2)
              .then(response => {
                return response.json()
              })
              .then(json => {
                console.log("GetStripeTransactionBySession response ", json)
                if (json?.response?.payment_status === "paid" || json?.response?.payment_status === "unpaid") {
                  let merchantPayload = json?.response?.metadata?.MerchantPayload
                  if (merchantPayload?.indexOf('repeat=') >= 0) {

                    const startIndex = merchantPayload?.indexOf('repeat=') + 7;
                    const endIndex = merchantPayload?.lastIndexOf('}');
                    const repeatJson = merchantPayload?.substring(startIndex, endIndex + 1);
                    repeatPayload = JSON.parse(repeatJson);
                    console.log("lorem Ipsum")
                    console.log("repeattttttt", repeatPayload)
                  }
                  const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + IsAutoAdjustment : "IsAutoAdjustment=" + false}`;

                  fetch(Settings.apiUrl + "Patient/SavePayment2" + payloadqueryStringFinal, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      "Token": token,
                    },
                    redirect: 'follow',
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      setPaymentChecking("success")
                      console.log(result)


                      if (result && repeatPayload) {
                        // openNotificationWithIcon('success', 'Payment succeeded successfully')
                        setPaymentChecking("success")
                        repeatMedicationRequest(repeatPayload)
                      }
                      else if (result) {
                        setPaymentChecking("success")
                        window.history.pushState('', '', '/paymentsuccess?checked=True');

                        setRepeatRequestSuccessful(true)
                        // setTimeout(() => {
                        //   navigate("/accounts")
                        // }, 2000)
                        // setIsModalOpen(true)
                      } else {
                        // openNotificationWithIcon('error', 'Payment unsuccessful')

                      }

                    });
                } 

              })
          } else {
            setPaymentChecking("failed")
          }
        }
        )
        .catch(err => console.log(err))
    }
    else if (callfrom == "web" && paymenttype == "windcave") {
      // {
      //   "SessionID": "000003000187955303d4b14c52e6bb4b",
      //     "PatientID": "8A006891-86E9-45DA-9170-865473AA5DC5"
      // }

      const item = await getUserId().then((userInfo) => {
        return JSON.parse(userInfo);
      });
      setPaymentChecking("loading")
      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");

      let IsAutoAdjustment = false
      let raw = {
        "PatientID": item.userid,
        "SessionID": sessionId
      }
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(raw)
      };
      fetch(Settings.apiUrl + "Patient/GetWindCaveSessionById", requestOptions)
        .then(response => {
          return response.json()
        })
        .then(json => {
          console.log("GetWindCaveSessionById response ", json)
          if (json.state === "complete") {


                  var myHeaders = new Headers();
                  myHeaders.append("Token", item.token);
                  myHeaders.append("Content-Type", "application/json");

                  var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify({
                      TempPaymentID: temppaymentid
                    }) 
                  };
            fetch(Settings.apiUrl + "Patient/GetTempPaymentSessionID", requestOptions)
                    .then(response => {
                      return response.json()
                    })
                    .then(json => {
                      console.log("Temp payment ID", json)
                      if (IsRxRequest === "True" && JSON.parse(json?.RepeatPayload)?.key1) {
                        IsAutoAdjustment = JSON.parse(json?.RepeatPayload)?.key1?.split("&IsAutoAdjustment=")[1]?.indexOf("True") >= 0
                      }
                      if (IsRxRequest === "True" && JSON.parse(JSON.parse(json?.RepeatPayload)?.key2)) {
                        repeatPayload = JSON.parse(JSON.parse(json?.RepeatPayload)?.key2)
                      }
                      const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + IsAutoAdjustment : "IsAutoAdjustment=" + false}`;

                      fetch(Settings.apiUrl + "Patient/SavePayment2" + payloadqueryStringFinal, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          "Token": token,
                        },
                        redirect: 'follow',
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          setPaymentChecking("success")
                          if (IsRxRequest === "True" && repeatPayload) {

                            repeatMedicationRequest(repeatPayload)
                          } else {
                            window.history.pushState('', '', '/paymentsuccess?checked=True');

                          }
                        })
                    })


                  // setTimeout(() => {
                  //   navigate("/accounts")
                  // }, 2000)



          }
        })
    }
    }, 5000)

  }, [])

  return (
    <div className="page-content-wrapper-inner">

      <div className="content-viewport">
        <div className="row row-margin-zero h-100 text-center d-flex justify-content-center">
          {isCallFromWeb ?
            <h1 className='mt-5 w-75 max-width-400'>{(paymentMethod === "poli" && !paymentData) || (paymentChecking === "loading") ?
              <>
                <img src="assets/images/processing.svg" />
                <h2>Payment Processing</h2>
              </>

              : sendingMedicationRepeatRequest ?
                <>
                  <img src="assets/images/repeating.svg" />
                  <h2>Repeating Prescription</h2>
                </>
                : (paymentMethod === "poli" && paymentData?.TransactionStatus?.toLowerCase() == "completed") || paymentChecking === "success" ?
                  <>
                    <img src="assets/images/Success.svg" />
                    <h2>Request Successful</h2>
                    {isCallFromRpeatRX ? repeatRequestSuccessful ? <p className="font-size-17">
                      Your payment is successful and your prescription has been sent to the practice. You can check the status of your prescription under Rx Request.</p> : <p>Your request failed. Please try again. If the problem persists, contact your practice for further help.</p> : null}
                    <button className="payment-returnpage-btn mt-5" onClick={() => {
                      if (isCallFromRpeatRX) {
                        localStorage.setItem("redirectedOnce", false)
                        navigate("/medications?RepeatSuccess=True")
                      } else {
                        navigate("/accounts")

                      }
                    }}>Continue</button>
                  </>
                  : paymentChecking === "pending" ?
                    <>
                      <img src="assets/images/failed.svg" />
                      <h2>Payment is still processing at stripe end.</h2>
                      <button className="payment-returnpage-btn mt-5" onClick={() => {
                        if (isCallFromRpeatRX) {
                          localStorage.setItem("redirectedOnce", false)
                          navigate("/medications?RepeatSuccess=True")
                        } else {
                          navigate("/accounts")

                        }
                      }}>Continue</button>
                    </>
                    : <>
                    <img src="assets/images/failed.svg" />
                    <h2>Request Failed</h2>
                    <button className="payment-returnpage-btn mt-5" onClick={() => {
                      if (isCallFromRpeatRX) {
                        localStorage.setItem("redirectedOnce", false)
                        navigate("/medications?RepeatSuccess=True")
                      } else {
                        navigate("/accounts")

                      }
                    }}>Continue</button>
                  </>}</h1>
            :

            <h1 className='mt-5 w-75 max-width-400'>{(paymentMethod === "poli" && !paymentData) || (paymentChecking === "loading") ? <>
              <img src="assets/images/processing.svg" />
              <h2>Payment Processing</h2>
            </> : sendingMedicationRepeatRequest ? <>
              <img src="assets/images/repeating.svg" />
              <h2>Repeating Prescription</h2>
            </>
              : (paymentMethod === "poli" && paymentData?.TransactionStatus?.toLowerCase() == "completed") || paymentChecking === "success" ?
                <>
                  <img src="assets/images/Success.svg" />
                  <h2>Request Successful</h2>
                  {isCallFromRpeatRX == false ? <p className="font-size-17">
                      Your payment is successful. Please tap on back arrow shaped button on top left corner to proceed further.</p> : 
                    isCallFromRpeatRX ? repeatRequestSuccessful ? <p className="font-size-17">
                        Your payment is successful and your prescription has been sent to the practice. You can check the status of your prescription under Rx Request. Please tap on back arrow shaped button on top left corner to proceed further.</p> : <p>Your request failed. Please try again. If the problem persists, contact your practice for further help.</p> : null
                    // {/* <button className="payment-returnpage-btn mt-5" onClick={() => { isCallFromRpeatRX ? navigate("/medications") : navigate("/accounts") }}>Continue</button> */}
                  }
                </>
                :
                  paymentChecking !== "idle" && paymentChecking === "pending" ?
                    <>
                      <img src="assets/images/failed.svg" />
                      <h2>Payment is still processing at stripe end.</h2>
                      <button className="payment-returnpage-btn mt-5" onClick={() => {
                        if (isCallFromRpeatRX) {
                          localStorage.setItem("redirectedOnce", false)
                          navigate("/medications?RepeatSuccess=True")
                        } else {
                          navigate("/accounts")

                        }
                      }}>Continue</button>
                    </>
                    : paymentChecking !== "idle" && <>
                  <img src="assets/images/failed.svg" />
                    <h2>Request Failed</h2>
                    {/* <button className="payment-returnpage-btn mt-5" onClick={() => { isCallFromRpeatRX ? navigate("/medications") : navigate("/accounts") }}>Continue</button> */}
                </>
            }</h1>}
        </div>
      </div>
      <Modal
        open={isModalOpen}
        title={repeatRequestSuccessful ? 'Payment Successful' : 'Payment Failed'}
        maskClosable={false}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        afterClose={() => {
          navigate("/medications?RepeatSuccess=True");
          localStorage.setItem("redirectedOnce", false)
        }}
        onOk={() => { setIsModalOpen(false) }}
      >
        {repeatRequestSuccessful ? <p>
          Your payment is successful and your prescription has been sent to the practice. You can check the status of your prescription under Rx Request.</p> : <p>Your request failed. Please try again. If the problem persists, contact your practice for further help.</p>}
      </Modal>
    </div>
  );
};


export default PaymentSuccessRoute;
