import { GETTING_IMMUNE, GETTING_IMMUNE_SUCCESS, GETTING_IMMUNE_MORE_SUCCESS, GETTING_IMMUNE_FAILURE, GETTING_IMMUNE_FINISHED } from "../constants/Constant";

//inital state
const initalState = {
    Immune: [],
    isGettingImmune: false,
    immuneError: false
}

//export default state
export default function immuneReducer(state = initalState, action) {
    switch (action.type) {
        case GETTING_IMMUNE:
            return {
                ...state,
                Immune: [],
                isGettingImmune: true
            }

        case GETTING_IMMUNE_SUCCESS:
            return {
                ...state,
                isGettingImmune: false,
                //Immune: [...state.Immune, ...action.data],
                Immune: action.data
            }

        case GETTING_IMMUNE_MORE_SUCCESS:
            return {
                ...state,
                isGettingImmune: false,
                Immune: [...state.Immune, ...action.data]
            }

        case GETTING_IMMUNE_FAILURE:
            return {
                ...state,
                Immune: [],
                isGettingImmune: false,
                immuneError: true
            }
        case GETTING_IMMUNE_FINISHED:
            return {
                ...state,
                    Immune: [],
                isGettingImmune: false,
                immuneError: false
            }
        default:
            return state
    }
}