import { MESSAGE_RECIPIENTS_LISTING, MESSAGE_RECIPIENTS_LISTING_SUCCESS, MESSAGE_RECIPIENTS_LISTING_LOADING, MESSAGE_RECIPIENTS_LISTING_ERROR, MESSAGE_RECIPIENTS_LISTING_FAILED } from '../../constants/Constant'


//SET INITAILS STATE
const initialState = {
    recipientsData: null,
    recipientsDataLoading: false,
    recipientsDataSuccess: false,
    recipientsDataFailed: false,
    recipientsDataError: null,
    recipientsDataStatus: "idle",
  }

//export the defult state

export default function messageRecipientsReducer(state = initialState, action) {
    switch (action.type) {
        case MESSAGE_RECIPIENTS_LISTING:
            return {
                ...state,
                recipientsData: null,
                recipientsDataLoading: true,
                recipientsDataStatus: "loading",
            }

        case MESSAGE_RECIPIENTS_LISTING_SUCCESS:
            return {
                ...state,
                recipientsDataLoading: false,
                recipientsData: action.data,
                recipientsDataSuccess: true,
                recipientsDataStatus: "success"
            }

        case MESSAGE_RECIPIENTS_LISTING_FAILED:
            return {
                ...state,
                recipientsData: null,
                recipientsDataLoading: false,
                recipientsDataStatus: "failed",
                sendMessageListingError: action.data,
                recipientsDataFailed: true,
            }

        default:
            return state
    }
}