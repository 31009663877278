import { GETTING_IMMUNE, GETTING_IMMUNE_SUCCESS, GETTING_IMMUNE_MORE_SUCCESS, GETTING_IMMUNE_FAILURE, GETTING_IMMUNE_FINISHED, GETTING_IMMUNE_ADMINISTERED, GETTING_IMMUNE_ADMINISTERED_SUCCESS, GETTING_IMMUNE_ADMINISTERED_MORE_SUCCESS, GETTING_IMMUNE_ADMINISTERED_FAILURE, GETTING_IMMUNE_ADMINISTERED_FINISHED, GETTING_IMMUNE_DUE, GETTING_IMMUNE_DUE_SUCCESS, GETTING_IMMUNE_DUE_MORE_SUCCESS, GETTING_IMMUNE_DUE_FAILURE, GETTING_IMMUNE_DUE_FINISHED } from "../constants/Constant";
import getAllImmune from "../services/ImmuneService";
import getUserId from "../core/GetToken";
import Settings from '../config/Settings'


//get all immune
export const fetchDueImmunefromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getImmune())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: pageSize,
                PageNumber: pageNumber,
                TypeID: 3,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            fetch(Settings.apiUrl + "Patient/Immunization", requestOptions)
                .then(data => data.json())
                .then(json => {
                    if (json) {

                        json[0].hasOwnProperty('entry') ? dispatch(getImmuneSuccess(json[0].entry)) : dispatch(getImmuneFinshed())
                    } else {
                        dispatch(getImmuneFinshed())
                    }

                })
                .catch(err => dispatch(getImmuneFail(err)))

           
        })
    }
}

//get all immune
export const fetchMoreDueImmunefromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getImmune())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&TypeID=3'
            getAllImmune(queryString)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getImmuneMoreSuccess(json[0].entry)) : dispatch(getImmuneFinshed())
                })
                .catch(err => dispatch(getImmuneFail(err)))
        })
    }
}



const getImmune = () => {
    return {
        type: GETTING_IMMUNE_DUE
    }
}

//data success
const getImmuneSuccess = (data) => {
    return {
        type: GETTING_IMMUNE_DUE_SUCCESS,
        data,
    }
}

//data success
const getImmuneMoreSuccess = (data) => {
    return {
        type: GETTING_IMMUNE_DUE_MORE_SUCCESS,
        data,
    }
}

//data failed
const getImmuneFail = () => {
    return {
        type: GETTING_IMMUNE_DUE_FAILURE
    }
}

const getImmuneFinshed = () => {
    return {
        type: GETTING_IMMUNE_DUE_FINISHED
    };
}

