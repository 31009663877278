import { GETTING_IMMUNE_DUE, GETTING_IMMUNE_DUE_SUCCESS, GETTING_IMMUNE_DUE_MORE_SUCCESS, GETTING_IMMUNE_DUE_FAILURE, GETTING_IMMUNE_DUE_FINISHED } from "../constants/Constant";

//inital state
const initalState = {
    DueImmune: [],
    isGettingImmune: false,
    DueimmuneError: false
}

//export default state
export default function DueImmune(state = initalState, action) {
    switch (action.type) {
        case GETTING_IMMUNE_DUE:
            return {
                ...state,
                DueImmune: [],
                isGettingImmune: true
            }

        case GETTING_IMMUNE_DUE_SUCCESS:
            return {
                ...state,
                isGettingImmune: false,
                DueImmune: action.data
                //DueImmune: [...state.DueImmune, ...action.data],
                // Immune: action.data
            }

        case GETTING_IMMUNE_DUE_MORE_SUCCESS:
            return {
                ...state,
                isGettingImmune: false,
                DueImmune: [...state.DueImmune, ...action.data]
            }

        case GETTING_IMMUNE_DUE_FAILURE:
            return {
                ...state,
                    DueImmune: [],
                isGettingImmune: false,
                DueimmuneError: true
            }
        case GETTING_IMMUNE_DUE_FINISHED:
            return {
                ...state,
                        DueImmune: [],
                isGettingImmune: false,
                DueimmuneError: false
            }
        default:
            return state
    }
}