import { GETTING_IMMUNE_ADMINISTERED, GETTING_IMMUNE_ADMINISTERED_SUCCESS, GETTING_IMMUNE_ADMINISTERED_MORE_SUCCESS, GETTING_IMMUNE_ADMINISTERED_FAILURE, GETTING_IMMUNE_ADMINISTERED_FINISHED } from "../constants/Constant";

//inital state
const initalState = {
    AddImmune: [],
    isGettingImmune: false,
    AddimmuneError: false
}

//export default state
export default function AdministeredImmune(state = initalState, action) {
    switch (action.type) {
        case GETTING_IMMUNE_ADMINISTERED:
            return {
                ...state,
                AddImmune: [],
                isGettingImmune: true
            }

        case GETTING_IMMUNE_ADMINISTERED_SUCCESS:
            return {
                ...state,
                isGettingImmune: false,
                AddImmune: action.data //[...state.AddImmune, ...action.data],
                // Immune: action.data
            }

        case GETTING_IMMUNE_ADMINISTERED_MORE_SUCCESS:
            return {
                ...state,
                isGettingImmune: false,
                AddImmune: [...state.AddImmune, ...action.data]
            }

        case GETTING_IMMUNE_ADMINISTERED_FAILURE:
            return {
                ...state,
                    AddImmune: [],
                isGettingImmune: false,
                AddimmuneError: true
            }
        case GETTING_IMMUNE_ADMINISTERED_FINISHED:
            return {
                ...state,
                        AddImmune: [],
                isGettingImmune: false,
                AddimmuneError: false
            }
        default:
            return state
    }
}