import { DatePicker, Input, Modal, notification } from 'antd';
import indicilogo from "../assets/indicilogo.png";
import signupBanner from "../assets/signup-page.png";
import profileplace from "../assets/profileplace.png";
import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Settings from '../../shared/config/Settings';
import moment from 'moment';
import ReCAPTCHA from 'react-google-recaptcha';
import { emailValidator } from '../../shared/core/utils';

const Signup = props => {
    const [isLoadingSignupToken, setIsLoadingSignupToken] = useState(false)
    const [isSignupTokenExpired, setIsSignupTokenExpired] = useState(false)
    const [familyName, setFamilyName] = useState("")
    const [familyNameErrMsg, setFamilyNameErrMsg] = useState("")
    const [firstName, setFirstName] = useState("")
    const [firstNameErrMsg, setFirstNameErrMsg] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [middleNameErrMsg, setMiddleNameErrMsg] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [dateOfBirthErrMsg, setDateOfBirthErrMsg] = useState("")
    const [nhiNum, setNhiNum] = useState("")
    const [nhiNumErrMsg, setNhiNumErrMsg] = useState("")
    // const [profileImage, setProfileImage] = useState("")
    const [practiceName, setPracticeName] = useState("")
    const [practiceID, setPracticeID] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [emailAddressErrMsg, setEmailAddressErrMsg] = useState("")
    const [signupErrMsg, setSignupErrMsg] = useState("")
    const [signupEmailErrMsg, setSignupEmailErrMsg] = useState("")
    const [signupSuccessModalVisible, setSignupSuccessModalVisible] = useState(false)
    const [signupSuccessModalVisible2, setSignupSuccessModalVisible2] = useState(false)

    const handleSubmit = () => {
        let raw = {
            "resourceType": "string",
            "entry": [
                {
                    "fullUrl": "",
                    "resource": {
                        "id": "string",
                        "resourceType": "string",
                        "identifier": [
                            {
                                "use": "string",
                                "type": {
                                    "coding": [
                                        {
                                            "system": "string",
                                            "code": "string",
                                            "display": "string"
                                        }
                                    ],
                                    "text": "string"
                                },
                                "system": "string",
                                "value": "string",
                                "period": {
                                    "start": "2022-11-17T07:32:29.135Z",
                                    "end": "2022-11-17T07:32:29.135Z"
                                },
                                "assigner": {
                                    "alias": [
                                        "JTest"
                                    ],
                                    "contact": [
                                        {
                                            "purpose": {
                                                "coding": [
                                                    {
                                                        "system": "string",
                                                        "code": "string",
                                                        "display": "string"
                                                    }
                                                ],
                                                "text": "string"
                                            },
                                            "name": [
                                                {
                                                    "text": "Wwss  xxss",
                                                    "family": "xxss",
                                                    "given": [
                                                        "Wwssv"
                                                    ],
                                                    "prefix": [
                                                        "Dr"
                                                    ]
                                                }
                                            ],
                                            "telecom": {
                                                "system": "string",
                                                "value": "string",
                                                "use": "string",
                                                "rank": 0,
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.135Z",
                                                    "end": "2022-11-17T07:32:29.135Z"
                                                }
                                            },
                                            "address": {
                                                "use": "string",
                                                "type": "string",
                                                "text": "string",
                                                "line": [
                                                    "string"
                                                ],
                                                "city": "string",
                                                "district": "string",
                                                "state": "string",
                                                "postalCode": "string",
                                                "country": "string",
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.135Z",
                                                    "end": "2022-11-17T07:32:29.135Z"
                                                }
                                            }
                                        }
                                    ],
                                    "resourceType": "string",
                                    "identifier": {

                                    },
                                    "active": true,
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    },
                                    "name": "string",
                                    "partOf": {

                                    },
                                    "endpoint": {
                                        "identifier": [
                                            {

                                            }
                                        ],
                                        "connectionType": {
                                            "system": "string",
                                            "version": "string",
                                            "code": "string",
                                            "display": "string",
                                            "userSelected": true
                                        },
                                        "managingOrganization": {

                                        },
                                        "contact": [
                                            {
                                                "system": "string",
                                                "value": "string",
                                                "use": "string",
                                                "rank": 0,
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.135Z",
                                                    "end": "2022-11-17T07:32:29.135Z"
                                                }
                                            }
                                        ],
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        },
                                        "payloadType": [
                                            {
                                                "coding": [
                                                    {
                                                        "system": "string",
                                                        "code": "string",
                                                        "display": "string"
                                                    }
                                                ],
                                                "text": "string"
                                            }
                                        ],
                                        "payloadMimeType": [
                                            "string"
                                        ],
                                        "resourceType": "string",
                                        "status": "string",
                                        "name": "string",
                                        "address": "string",
                                        "header": [
                                            "string"
                                        ]
                                    }
                                }
                            }
                        ],
                        "status": "string",
                        "code": {
                            "coding": [
                                {
                                    "system": "string",
                                    "code": "string",
                                    "display": "string"
                                }
                            ],
                            "text": "string"
                        },
                        "active": true,
                        "name": [
                            {
                                "use": "string",
                                "text": firstName + " " + familyName,
                                "family": familyName,
                                "given": [
                                    firstName
                                ],
                                "prefix": "string",
                                "suffix": "string",
                                "period": {
                                    "start": "2022-11-17T07:32:29.136Z",
                                    "end": "2022-11-17T07:32:29.136Z"
                                }
                            }
                        ],
                        "telecom": [

                            {
                                "system": "email",
                                "value": emailAddress,
                                "use": "home"
                            }
                        ],
                        "gender": "string",
                        "birthDate": dateOfBirth,
                        "effectiveDateTime": "2022-11-17T07:32:29.136Z",
                        "issued": "2022-11-17T07:32:29.136Z",
                        "authoredOn": "2022-11-17T07:32:29.136Z",
                        "receivedTime": "2022-11-17T07:32:29.136Z",
                        "basedOn": [
                            {
                                "reference": "string"
                            }
                        ],
                        "specimen": [
                            {
                                "reference": "string"
                            }
                        ],
                        "subject": {
                            "reference": "string"
                        },
                        "deceasedBoolean": true,
                        "deceasedDateTime": "2022-11-17T07:32:29.136Z",
                        "address": [
                            {
                                "use": "string",
                                "type": "string",
                                "text": "string",
                                "line": [
                                    "string"
                                ],
                                "city": "string",
                                "district": "string",
                                "state": "string",
                                "postalCode": "string",
                                "country": "string",
                                "period": {
                                    "start": "2022-11-17T07:32:29.136Z",
                                    "end": "2022-11-17T07:32:29.136Z"
                                }
                            }
                        ],
                        "maritalStatus": {
                            "coding": [
                                {
                                    "system": "string",
                                    "code": "string",
                                    "display": "string"
                                }
                            ],
                            "text": "string"
                        },
                        "multipleBirthBoolean": true,
                        "multipleBirthInteger": 0,
                        "photo": [
                            {
                                "contentType": "string",
                                "language": "string",
                                "data": "string",
                                "url": "string",
                                "size": 0,
                                "hash": "string",
                                "title": "string",
                                "creation": "2022-11-17T07:32:29.136Z"
                            }
                        ],
                        "contact": [
                            {
                                "relationship": [
                                    {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    }
                                ],
                                "name": {
                                    "use": "string",
                                    "text": "string",
                                    "family": "string",
                                    "given": [
                                        "string"
                                    ],
                                    "prefix": "string",
                                    "suffix": "string",
                                    "period": {
                                        "start": "2022-11-17T07:32:29.136Z",
                                        "end": "2022-11-17T07:32:29.136Z"
                                    }
                                },
                                "telecom": [
                                    {
                                        "system": "string",
                                        "value": "string",
                                        "use": "string",
                                        "rank": 0,
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        }
                                    }
                                ],
                                "address": {
                                    "use": "string",
                                    "type": "string",
                                    "text": "string",
                                    "line": [
                                        "string"
                                    ],
                                    "city": "string",
                                    "district": "string",
                                    "state": "string",
                                    "postalCode": "string",
                                    "country": "string",
                                    "period": {
                                        "start": "2022-11-17T07:32:29.136Z",
                                        "end": "2022-11-17T07:32:29.136Z"
                                    }
                                },
                                "organization": {
                                    "alias": [
                                        "string"
                                    ],
                                    "contact": [
                                        {
                                            "purpose": {
                                                "coding": [
                                                    {
                                                        "system": "string",
                                                        "code": "string",
                                                        "display": "string"
                                                    }
                                                ],
                                                "text": "string"
                                            },
                                            "name": {
                                                "use": "string",
                                                "text": "string",
                                                "family": "string",
                                                "given": [
                                                    "string"
                                                ],
                                                "prefix": "string",
                                                "suffix": "string",
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.136Z",
                                                    "end": "2022-11-17T07:32:29.136Z"
                                                }
                                            },
                                            "telecom": {
                                                "system": "string",
                                                "value": "string",
                                                "use": "string",
                                                "rank": 0,
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.136Z",
                                                    "end": "2022-11-17T07:32:29.136Z"
                                                }
                                            },
                                            "address": {
                                                "use": "string",
                                                "type": "string",
                                                "text": "string",
                                                "line": [
                                                    "string"
                                                ],
                                                "city": "string",
                                                "district": "string",
                                                "state": "string",
                                                "postalCode": "string",
                                                "country": "string",
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.136Z",
                                                    "end": "2022-11-17T07:32:29.136Z"
                                                }
                                            }
                                        }
                                    ],
                                    "resourceType": "string",
                                    "identifier": {
                                        "use": "string",
                                        "type": {
                                            "coding": [
                                                {
                                                    "system": "string",
                                                    "code": "string",
                                                    "display": "string"
                                                }
                                            ],
                                            "text": "string"
                                        },
                                        "system": "string",
                                        "value": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        },
                                        "assigner": {

                                        }
                                    },
                                    "active": true,
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    },
                                    "name": "string",
                                    "partOf": {

                                    },
                                    "endpoint": {
                                        "identifier": [
                                            {
                                                "use": "string",
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "string",
                                                            "code": "string",
                                                            "display": "string"
                                                        }
                                                    ],
                                                    "text": "string"
                                                },
                                                "system": "string",
                                                "value": "string",
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.136Z",
                                                    "end": "2022-11-17T07:32:29.136Z"
                                                },
                                                "assigner": {

                                                }
                                            }
                                        ],
                                        "connectionType": {
                                            "system": "string",
                                            "version": "string",
                                            "code": "string",
                                            "display": "string",
                                            "userSelected": true
                                        },
                                        "managingOrganization": {

                                        },
                                        "contact": [
                                            {
                                                "system": "string",
                                                "value": "string",
                                                "use": "string",
                                                "rank": 0,
                                                "period": {
                                                    "start": "2022-11-17T07:32:29.136Z",
                                                    "end": "2022-11-17T07:32:29.136Z"
                                                }
                                            }
                                        ],
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        },
                                        "payloadType": [
                                            {
                                                "coding": [
                                                    {
                                                        "system": "string",
                                                        "code": "string",
                                                        "display": "string"
                                                    }
                                                ],
                                                "text": "string"
                                            }
                                        ],
                                        "payloadMimeType": [
                                            "string"
                                        ],
                                        "resourceType": "string",
                                        "status": "string",
                                        "name": "string",
                                        "address": "string",
                                        "header": [
                                            "string"
                                        ]
                                    }
                                },
                                "period": {
                                    "start": "2022-11-17T07:32:29.136Z",
                                    "end": "2022-11-17T07:32:29.136Z"
                                },
                                "gender": "string"
                            }
                        ],
                        "communication": [
                            {
                                "language": {
                                    "coding": [
                                        {
                                            "system": "string",
                                            "code": "string",
                                            "display": "string"
                                        }
                                    ],
                                    "text": "string"
                                },
                                "preferred": true
                            }
                        ],
                        "generalPractitioner": {
                            "alias": [
                                "string"
                            ],
                            "contact": [
                                {
                                    "purpose": {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    },
                                    "name": {
                                        "use": "string",
                                        "text": "string",
                                        "family": "string",
                                        "given": [
                                            "string"
                                        ],
                                        "prefix": "string",
                                        "suffix": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        }
                                    },
                                    "telecom": {
                                        "system": "string",
                                        "value": "string",
                                        "use": "string",
                                        "rank": 0,
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        }
                                    },
                                    "address": {
                                        "use": "string",
                                        "type": "string",
                                        "text": "string",
                                        "line": [
                                            "string"
                                        ],
                                        "city": "string",
                                        "district": "string",
                                        "state": "string",
                                        "postalCode": "string",
                                        "country": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.136Z",
                                            "end": "2022-11-17T07:32:29.136Z"
                                        }
                                    }
                                }
                            ],
                            "resourceType": "string",
                            "identifier": {
                                "use": "string",
                                "type": {
                                    "coding": [
                                        {
                                            "system": "string",
                                            "code": "string",
                                            "display": "string"
                                        }
                                    ],
                                    "text": "string"
                                },
                                "system": "string",
                                "value": "string",
                                "period": {
                                    "start": "2022-11-17T07:32:29.136Z",
                                    "end": "2022-11-17T07:32:29.136Z"
                                },
                                "assigner": {

                                }
                            },
                            "active": true,
                            "type": {
                                "coding": [
                                    {
                                        "system": "string",
                                        "code": "string",
                                        "display": "string"
                                    }
                                ],
                                "text": "string"
                            },
                            "name": "string",
                            "partOf": {

                            },
                            "endpoint": {
                                "identifier": [
                                    {
                                        "use": "string",
                                        "type": {
                                            "coding": [
                                                {
                                                    "system": "string",
                                                    "code": "string",
                                                    "display": "string"
                                                }
                                            ],
                                            "text": "string"
                                        },
                                        "system": "string",
                                        "value": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        },
                                        "assigner": {

                                        }
                                    }
                                ],
                                "connectionType": {
                                    "system": "string",
                                    "version": "string",
                                    "code": "string",
                                    "display": "string",
                                    "userSelected": true
                                },
                                "managingOrganization": {

                                },
                                "contact": [
                                    {
                                        "system": "string",
                                        "value": "string",
                                        "use": "string",
                                        "rank": 0,
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        }
                                    }
                                ],
                                "period": {
                                    "start": "2022-11-17T07:32:29.137Z",
                                    "end": "2022-11-17T07:32:29.137Z"
                                },
                                "payloadType": [
                                    {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    }
                                ],
                                "payloadMimeType": [
                                    "string"
                                ],
                                "resourceType": "string",
                                "status": "string",
                                "name": "string",
                                "address": "string",
                                "header": [
                                    "string"
                                ]
                            }
                        },
                        "managingOrganization": {
                            "alias": [
                                "string"
                            ],
                            "contact": [
                                {
                                    "purpose": {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    },
                                    "name": {
                                        "use": "string",
                                        "text": "string",
                                        "family": "string",
                                        "given": [
                                            "string"
                                        ],
                                        "prefix": "string",
                                        "suffix": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        }
                                    },
                                    "telecom": {
                                        "system": "string",
                                        "value": "string",
                                        "use": "string",
                                        "rank": 0,
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        }
                                    },
                                    "address": {
                                        "use": "string",
                                        "type": "string",
                                        "text": "string",
                                        "line": [
                                            "string"
                                        ],
                                        "city": "string",
                                        "district": "string",
                                        "state": "string",
                                        "postalCode": "string",
                                        "country": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        }
                                    }
                                }
                            ],
                            "resourceType": "string",
                            "identifier": {
                                "use": "string",
                                "type": {
                                    "coding": [
                                        {
                                            "system": "string",
                                            "code": "string",
                                            "display": "string"
                                        }
                                    ],
                                    "text": "string"
                                },
                                "system": "string",
                                "value": "string",
                                "period": {
                                    "start": "2022-11-17T07:32:29.137Z",
                                    "end": "2022-11-17T07:32:29.137Z"
                                },
                                "assigner": {

                                }
                            },
                            "active": true,
                            "type": {
                                "coding": [
                                    {
                                        "system": "string",
                                        "code": "string",
                                        "display": "string"
                                    }
                                ],
                                "text": "string"
                            },
                            "name": "string",
                            "partOf": {

                            },
                            "endpoint": {
                                "identifier": [
                                    {
                                        "use": "string",
                                        "type": {
                                            "coding": [
                                                {
                                                    "system": "string",
                                                    "code": "string",
                                                    "display": "string"
                                                }
                                            ],
                                            "text": "string"
                                        },
                                        "system": "string",
                                        "value": "string",
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        },
                                        "assigner": {

                                        }
                                    }
                                ],
                                "connectionType": {
                                    "system": "string",
                                    "version": "string",
                                    "code": "string",
                                    "display": "string",
                                    "userSelected": true
                                },
                                "managingOrganization": {

                                },
                                "contact": [
                                    {
                                        "system": "string",
                                        "value": "string",
                                        "use": "string",
                                        "rank": 0,
                                        "period": {
                                            "start": "2022-11-17T07:32:29.137Z",
                                            "end": "2022-11-17T07:32:29.137Z"
                                        }
                                    }
                                ],
                                "period": {
                                    "start": "2022-11-17T07:32:29.137Z",
                                    "end": "2022-11-17T07:32:29.137Z"
                                },
                                "payloadType": [
                                    {
                                        "coding": [
                                            {
                                                "system": "string",
                                                "code": "string",
                                                "display": "string"
                                            }
                                        ],
                                        "text": "string"
                                    }
                                ],
                                "payloadMimeType": [
                                    "string"
                                ],
                                "resourceType": "string",
                                "status": "string",
                                "name": "string",
                                "address": "string",
                                "header": [
                                    "string"
                                ]
                            }
                        },
                        "link": [
                            {
                                "other": {

                                },
                                "type": "string"
                            }
                        ],
                        "collection": {
                            "collectedDateTime": "2022-11-17T07:32:29.137Z"
                        },
                        "presentedForm": [
                            {
                                "contentType": "string",
                                "data": "string",
                                "FilePath": "string"
                            }
                        ],
                        "result": [
                            {
                                "ObservationList": [
                                    {

                                    }
                                ],
                                "reference": "string"
                            }
                        ],
                        "extension": [
                            {
                                "url": "NHI",
                                "valueString": nhiNum
                            },
                            {
                                "url": "MiddleName",
                                "valueString": "TestMiddle"
                            }
                        ],
                        "valueString": "string"
                    }
                }
            ],
            "Token": "string"
        }
    }
    const openNotification = (placement, type, msg,) => {

        notification[type]({
            message: msg,
            placement,
        });
    };
    const registerUser = () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {

            method: 'POST',
            headers: myHeaders,

            redirect: 'follow',
            body: JSON.stringify({
                FirstName: firstName,
                MiddleName: middleName,
                LastName: familyName,
                NHI: nhiNum,
                DateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD"),
                Email: emailAddress,
                PracticeID: practiceID ///get from first api call
            })

        };
        fetch(Settings.apiUrl + "Patient/UpdateProfileRegistrationIndici", requestOptions)
            .then(res => res.json())
            .then(json => {
                if (json?.Message === "An error has occurred.") {
                    openNotification("topRight", "error", json.Message)
                    setSignupErrMsg("There was error in registration process, please contact administrator.")
                }
                else if (json === -2) {
                    openNotification("topRight", "error", "The information you have provided is already registered with myIndici.")
                    setSignupErrMsg("The information you have provided is already registered with myIndici.")

                }
                else if (json === -3) {
                    openNotification("topRight", "error", "Provided email is already registered with Myindici.")
                    setSignupErrMsg("Provided email is already registered with Myindici.")

                }
                else if (json === -4) {
                    openNotification("topRight", "error", "Patient age must be greater than 16.")
                    setSignupErrMsg("Patient age must be greater than 16.")

                }
                else if (json === -6) {
                    openNotification("topRight", "error", "Provided email does not match with any Indici record.")
                    setSignupErrMsg("Provided email does not match with any Indici record.")

                }
                else if (json === -7) {
                    openNotification("topRight", "error", "Provided DOB does not match with Indici record.")
                    setSignupErrMsg("Provided DOB does not match with Indici record.")

                }
                else if (json === -9) {
                    openNotification("topRight", "error", "Provided Name does not match with Indici record.")
                    setSignupErrMsg("Provided Name does not match with Indici record.")

                }
                else if (json === -8) {
                    openNotification("topRight", "error", "Provided NHI Number does not match with Indici record.")
                    setSignupErrMsg("Provided NHI Number does not match with Indici record.")

                }
                else if (json === -1 || json === -11) {
                    openNotification("topRight", "error", "The information you have provided does not match our records. Please try again or visit your practice where we will register you for myIndici.")

                    setSignupErrMsg("The information you have provided does not match our records. Please try again or visit your practice where we will register you for myIndici.")

                }
                else if (json === -5) {
                    openNotification("topRight", "error", "Please verify CAPTCHA")
                    setSignupErrMsg("Please verify CAPTCHA")

                }

                if (json > 0) {
                    if (json === 7) {

                        setSignupErrMsg("Registration completed successfully")
                        setSignupSuccessModalVisible(true)

                    }
                    else {
                        clearSearchFeilds();

                        setSignupErrMsg("Registration completed successfully")
                        setSignupSuccessModalVisible2(true)
                    }
                }
            })
            .catch(err => { })
    }
    const validateData = () => {
        let errCaught = false
        const emailErr = emailValidator(emailAddress?.trim())

        if (familyName == "") {
            setFamilyNameErrMsg("Family name cannot be empty")
            errCaught = true
        }
        if (firstName.trim() == "") {
            setFirstNameErrMsg("First name cannot be empty")
            errCaught = true
        }
        if (dateOfBirth == "") {
            setDateOfBirthErrMsg("Date of birth cannot be empty")
            errCaught = true
        }
        // if (nhiNum.trim() == "") {
        //     setNhiNumErrMsg("NHI cannot be empty")
        //     errCaught = true
        // }
        if (practiceName.trim() == "") {

            errCaught = true
        }
        if (emailAddress.trim() == "") {
            setEmailAddressErrMsg("Email address cannot be empty")
            errCaught = true
        }
        if (emailErr) {
            setEmailAddressErrMsg("You have entered an invalid email address")
            errCaught = true
        }
        return errCaught
    }
    const handleSignupSubmit = () => {
        const validated = validateData()
        if (validated) {
            return
        }

        var myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "Email": emailAddress
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_API_AUTH_IDENTITY_URL.replace("Authentication2", "")}GetApiUrl`, requestOptions)
            .then(res => res.json())
            .then(data => {
                if (data?.APIURL !== "") {
                    setSignupEmailErrMsg("Email is already registered at practice " + data?.PracticeName)

                } else {
                    setSignupEmailErrMsg("")
                    registerUser()
                }
            })
            .catch(err => {

            })
    }

    const clearSearchFeilds=()=>{
        setFamilyName('');
        setDateOfBirth('');
        setFirstName('');
        setMiddleName('');
        setNhiNum('');
        setEmailAddress('');
    }

    const search = useLocation().search
    const navigate = useNavigate()
    const [registerBtnDisabled, setRegisterBtnDisabled] = useState(true)
    const onRecaptchaExpired = () => {
        setRegisterBtnDisabled(true)
    }
    const onRecaptchaChange = () => {
        setRegisterBtnDisabled(!registerBtnDisabled)
    }
    useLayoutEffect(() => {
        setIsLoadingSignupToken(true)
        const id = new URLSearchParams(search).get('ID');
        if (id) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {

                method: 'POST',
                headers: myHeaders,

                redirect: 'follow',


            };
            fetch("https://identityserverloginapi.itsmyhealth.nz/api/GetApiUrlByToken?id=" + id, requestOptions)
                .then(res => res.json())
                .then(json => {
                    if (json?.PracticeName) {
                        setPracticeID(json.PracticeID)
                        setPracticeName(json.PracticeName)
                        if (json.TokenExpiryTime) {
                            let currentDate = new Date();
                            let tokenExpiryDate = new Date(json.TokenExpiryTime?.split("T")[0])
                            if (currentDate < tokenExpiryDate) {
                                setIsSignupTokenExpired(false)
                            } else {
                                setIsSignupTokenExpired(true)

                            }
                        }
                        Settings.apiUrl = json.ApiUrl
                    } else {

                    }
                    setIsLoadingSignupToken(false)
                })

        }
    }, [])
    return (
        !isLoadingSignupToken ?
            practiceID !== "" && !isSignupTokenExpired ?
                <>
                <div className="signup-page">
            <img className="signup-logo" src={indicilogo} alt="Logo" />
            <div className="sign-content row row-cols-2">
                <div className="sign-left col-4 d-none d-md-flex">
                    <img src={signupBanner} alt="" />
                </div>
                        <div className="sign-right col-12 col-md-8 p-4 ">
                            <div className='d-flex justify-content-between'>

                                <h3 className="header text-primary">Register Patient</h3>
                                <p className=' text-primary'><b>Practice</b>: {practiceName}</p>
                            </div>
                    <div className="divider"></div>
                    <h5 className="my-3 fs-2 fw-bold">Please Enter Your Details Here</h5>
                                <div className="form-content row row-cols-2 gap-4 justify-content-between">
                                    <div className='col-6'>

                                        <Input autoComplete="off" value={firstName} onChange={(e) => {
                                            setFirstName(e.target.value);
                                            setFirstNameErrMsg("")
                                        }} className="form-item my-2 py-2 " placeholder="First Name" />
                            <p className='text-danger my-1 ml-1'>{firstNameErrMsg}</p>
                                    </div>
                                    <div className='col-6'>

                                        <Input autoComplete="off" value={familyName} onChange={(e) => {
                                            setFamilyName(e.target.value);
                                            setFamilyNameErrMsg("")
                                        }} className="form-item my-2 py-2 " placeholder="Family Name" />
                                        <p className='text-danger my-1 ml-1'>{familyNameErrMsg}</p>
                                    </div>
                                    <div className='col-6'>

                                        <Input autoComplete="off" value={middleName} onChange={(e) => {
                                            setMiddleName(e.target.value);
                                            setMiddleNameErrMsg("")
                                        }} className="form-item my-2 py-2 " placeholder="Middle Name" />
                                        <p className='text-danger my-1 ml-1'>{middleNameErrMsg}</p>
                                    </div>
                                    <div className='col-6'>

                                        <Input autoComplete="off" value={nhiNum} onChange={(e) => {
                                        setNhiNum(e.target.value);
                                        setNhiNumErrMsg("")
                                        }} className="form-item my-2 py-2 " placeholder="NHI Number" />
                                    <p className='text-danger my-1 ml-1'>{nhiNumErrMsg}</p>

                                    </div>
                                    <div className='col-6'>

                                        <Input autoComplete="off" value={emailAddress} onChange={(e) => {
                                setEmailAddress(e.target.value);
                                setEmailAddressErrMsg("")
                                        }} className="form-item my-2 py-2 " placeholder="Email Address" />
                                        <p className='text-danger my-1 ml-1'>{emailAddressErrMsg}</p>
                                    </div>
                                    <div className='col-6'>

                                        <DatePicker autoComplete="off" value={dateOfBirth} inputReadOnly onChange={(e) => {
                                setDateOfBirth(e);
                                setDateOfBirthErrMsg("")
                                        }} className="form-item my-2 py-2 bg-white" placeholder="Date of Birth" format="DD-MM-YYYY" />
                                        <p className='text-danger my-1 ml-1'>{dateOfBirthErrMsg}</p>
                                    </div>
                    </div>
                                <p className='text-danger'>{signupEmailErrMsg}</p>
                            <div className="sign-btn w-100 d-flex  justify-content-between align-items-end mt-3">
                                <div className=''>

                                    <ReCAPTCHA
                                        className='d-inline-block'
                                        sitekey={`${process.env.REACT_APP_CAPTCHA_SITEKEY}`}
                                        onChange={onRecaptchaChange}
                                        onExpired={onRecaptchaExpired}
                                        badge={'bottomleft'}
                                    />
                                    </div>
                                    <button disabled={registerBtnDisabled} onClick={handleSignupSubmit} type="submit" className="btn btn-primary btn-block w-25 mb-1 "> Register </button>
                    </div>

                        </div>
                    </div>

                        <footer className="footer-ignore">
                            <small>Version 2.0.0.0<br />© Copyrights . All rights Reserved</small>
                        </footer>
                    </div>

                    <Modal title="MyIndici Registeration Status" open={signupSuccessModalVisible} onOk={() => {
                        setSignupSuccessModalVisible(false);
                        clearSearchFeilds();
                    }} cancelButtonProps={{ style: { display: 'none' } }}

                    >
                        <p>Dear {firstName + " " + familyName} </p>
                        <p>Thank you for registering on MyIndici!</p>


                        <p>You've been granted limited access on the MyIndici portal including:</p>
                        <ul>

                            <li>
                                Making appointments online (including phone & video appointments)
                            </li>
                            <li>
                                Reviewing billing information
                            </li>
                        </ul>


                        <p> At your next visit to the practice please show your ID (Passport, Driving License or any other photo ID) to practice staff to arrange access to the other modules.</p>
                    </Modal>
                    <Modal title="Account registered successfully" open={signupSuccessModalVisible2} onOk={() => { setSignupSuccessModalVisible2(false); clearSearchFeilds(); }} cancelButtonProps={{ style: { display: 'none' } }} afterClose={() => setTimeout(() => {
                        window.open("https://patientportal.myindici.co.nz", "_self")
                    }, 1000)}>
                        <p>Your registration with myIndici is completed, you will shortly get an email containing instructions about how to login to your account.</p>
                    </Modal>
                </> :
                <div className="signup-page">
                    <img className="signup-logo" src={indicilogo} alt="Logo" />
                    <div className="sign-content row row-cols-2">
                        <div className="sign-left col-4 d-none d-md-flex">
                            <img src={signupBanner} alt="" />
                        </div>
                        <div className="sign-right col-12 col-md-8 p-4 d-flex align-items-center justify-content-center">
                            <div className='error-box '>
                                <h4 className='fs-2 text-danger'>

                                    The link has expired. Please visit your practice where we will register you for myIndici
                                </h4>
                            </div>
                        </div>
                    </div>
                    <footer className="footer-ignore">
                        <small>Version 2.0.0.0<br />© Copyrights . All rights Reserved</small>
                    </footer>
                </div> : null
    );
}

export default Signup;

