import store from "../../configureStore"

const state = store.getState().IdentityServerReducer.identityApiData;

const Settings = {
	//apiUrl: 'https://patientportalapi.indici.ie/api/'
	//

	apiUrl: "https://portaltrainingapi.vitonta.com/api/"
};



export default Settings;

// apiUrl : 'https://stagingindiciconnect.itsmyhealth.nz/api/' https://erxindiciconnect.indici.nz/api/

// 'https://nhldevconnect.indici.ie/api/'
